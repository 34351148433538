import "core-js/modules/es.array.push.js";
import { mixins } from "@/plugins/mixins";
export default {
  name: "depositRule",
  mixins: [mixins],
  components: {},
  data() {
    return {
      currentId: null,
      detailDrawer: false,
      columns: [{
        label: '微信免押信用分',
        prop: 'wxRedit'
      }, {
        label: '押金金额',
        prop: 'deposit'
      }],
      topButtons: [{
        name: '新增',
        code: 'depositRule_add',
        type: 'primary',
        click: this.add,
        permissions: ['depositRule_add']
      }],
      linkButtons: [],
      searchItems: []
    };
  },
  methods: {
    add() {
      this.$router.push('/depositRuleAdd');
    }
  }
};